import React from 'react'
import { Box, Text } from '@chakra-ui/layout'
import type { TextProps } from '@chakra-ui/layout'

import { Address } from '~graphql/generated/graphql'
import { formatCity, formatStreetA, formatZip } from '~utils/helpers'

interface Props extends TextProps {
    address: Address
}

export default function AddressDisplay({ address, ...rest }: Props) {
    const { street_a, street_b, city, state, zip } = address

    return (
        <Box>
            <Text {...rest}>{formatStreetA(street_a)}</Text>
            {street_b && <Text {...rest}>{street_b}</Text>}
            <Text {...rest}>
                {formatCity(city)}, {state} {formatZip(zip)}
            </Text>
        </Box>
    )
}
