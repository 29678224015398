import React, { SetStateAction, useContext } from 'react'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import {
    Flex,
    Box,
    Text,
    Heading,
    Icon,
    Tooltip,
    HStack,
    Image,
} from '@chakra-ui/react'
import { MdBuild } from 'react-icons/md'
import { UserContext } from '~config/user-context'
import SidebarTab from './sidebar-tab'
import SidebarDrawer from './sidebar-drawer'
import { useClinic } from '~contexts/clinic-context'
import { BiArrowFromLeft, BiArrowFromRight } from 'react-icons/bi'
import Logo from '~images/logo/dark.svg'
import Small from '~images/logo/small-logo.svg'
import { useSidebarContent } from './tabs'
import { UserRoutes } from '~config/routes'

interface Props {
    isOpen: boolean
    setIsOpen: (e: boolean) => void
    submenu: string
    setSubmenu: (value: SetStateAction<string>) => void
}

const Sidebar: React.FC<Props> = ({
    isOpen,
    setIsOpen,
    submenu,
    setSubmenu,
}) => {
    const { clinic, organization } = useClinic()
    const { pathname } = useLocation()

    const { features } = clinic || {}

    const { sidebarItems } = useSidebarContent(features)

    return (
        <HStack h="full" spacing={0} zIndex={5}>
            <Flex
                direction="column"
                w={isOpen ? '276px' : '64px'}
                transition="0.4s"
                h="100%"
                bg="gray.900"
                whiteSpace="nowrap"
                justifyContent="space-between"
                // shadow="blue.right"
                zIndex={5}
            >
                <Box
                    overflow="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}
                >
                    <Box px={4} pl={isOpen ? 4 : 3} pb={4} pt={4}>
                        <Link to={UserRoutes.Home}>
                            <HStack spacing={3} align="center">
                                <Image
                                    src={isOpen ? Logo : Small}
                                    alt="Checkvet Logo"
                                    borderWidth={1}
                                    borderColor="black"
                                    h={9}
                                />
                                <Heading
                                    size="sm"
                                    opacity={isOpen ? 1 : 0}
                                    fontWeight={300}
                                    color="brand.500"
                                >
                                    {organization
                                        ? 'Enterprise'
                                        : 'Clinic Manager'}
                                </Heading>
                            </HStack>
                        </Link>
                    </Box>
                    <SidebarContent
                        isOpen={isOpen}
                        submenu={submenu}
                        setSubmenu={setSubmenu}
                    />
                </Box>
                <Tooltip isDisabled={isOpen} label="Expand" placement="right">
                    <Flex
                        w="100%"
                        position="relative"
                        textAlign={'left'}
                        px={3}
                        py={2}
                        align="center"
                        justify={isOpen ? 'center' : 'flex-start'}
                        cursor="pointer"
                        transition="all 0.2s"
                        bg={'gray.800'}
                        borderTopRadius="2xl"
                        onClick={() => {
                            if (!isOpen) setSubmenu('none')
                            else {
                                sidebarItems.forEach(p => {
                                    if (
                                        p.pages &&
                                        p.pages.some(
                                            sp =>
                                                sp.to &&
                                                pathname.includes(sp.to),
                                        )
                                    ) {
                                        setSubmenu(p.label)
                                    }
                                })
                            }
                            setIsOpen(!isOpen)
                        }}
                    >
                        <Flex
                            minW={8}
                            boxSize={8}
                            justify="center"
                            align="center"
                            ml={isOpen ? 1 : 0}
                            mr={isOpen ? 2 : 0}
                        >
                            <Icon
                                as={isOpen ? BiArrowFromRight : BiArrowFromLeft}
                                boxSize="20px"
                                color="white"
                            />
                        </Flex>
                        <Text color="white" opacity={isOpen ? 1 : 0}>
                            Collapse
                        </Text>
                    </Flex>
                </Tooltip>
            </Flex>
            <Flex
                maxW={isOpen || submenu === 'none' ? '0px' : '64px'}
                opacity={isOpen || submenu === 'none' ? 0 : 1}
                bg="gray.700"
                w="64px"
                shadow="blue.right"
                pt={`calc(68px + (48px * ${
                    6 +
                    (submenu === 'Booking'
                        ? 1
                        : submenu === 'Engagement'
                          ? 2
                          : submenu === 'Clinic'
                            ? 3
                            : 0)
                }))`}
                direction="column"
                transition="0.4s"
                h="full"
            >
                {sidebarItems
                    .find(p => p.label === submenu)
                    ?.pages?.map((page, index) => (
                        <SidebarTab
                            key={index}
                            {...page}
                            isOpen={false}
                            isSubPage
                            setSubmenu={setSubmenu}
                        />
                    ))}
            </Flex>
        </HStack>
    )
}

export default Sidebar

export const SidebarContent: React.FC<{
    isOpen: boolean
    submenu: string
    setSubmenu: (value: React.SetStateAction<string>) => void
}> = ({ isOpen, submenu, setSubmenu }) => {
    const { clinic, organization, isClinicAdmin } = useClinic()
    const { isAdmin } = useContext(UserContext)

    const { features } = clinic || {}
    const { sidebarItems, adminItems, enterpriseItems } =
        useSidebarContent(features)

    return (
        <>
            {!clinic && !organization && (
                <SidebarTab
                    key={'select_clinic'}
                    icon={MdBuild}
                    to={UserRoutes.SelectClinic}
                    label={'Select Clinic'}
                    isOpen={isOpen}
                    setSubmenu={setSubmenu}
                />
            )}
            {organization &&
                enterpriseItems.map(
                    ({ icon, label, to, pages, badge, isNew }) => (
                        <SidebarTab
                            key={label}
                            icon={icon}
                            to={to}
                            label={label}
                            badge={badge}
                            isNew={isNew}
                            isOpen={isOpen}
                            setSubmenu={setSubmenu}
                        />
                    ),
                )}
            {clinic &&
                sidebarItems.map(({ icon, label, to, pages, badge, isNew }) =>
                    to ? (
                        <SidebarTab
                            key={label}
                            icon={icon}
                            to={to}
                            label={label}
                            badge={badge}
                            isNew={isNew}
                            isOpen={isOpen}
                            setSubmenu={setSubmenu}
                        />
                    ) : pages ? (
                        <SidebarDrawer
                            key={label}
                            icon={icon}
                            label={label}
                            pages={pages}
                            isSidebarOpen={isOpen}
                            submenu={submenu}
                            setSubmenu={setSubmenu}
                        />
                    ) : (
                        <div key={label} />
                    ),
                )}
            {/* {clinic && (isClinicAdmin || isAdmin) && (
                <>
                    {sidebarItems.map(
                        ({ icon, label, to, pages, badge, isNew }) =>
                            to ? (
                                <SidebarTab
                                    key={label}
                                    icon={icon}
                                    to={to}
                                    label={label}
                                    badge={badge}
                                    isNew={isNew}
                                    isOpen={isOpen}
                                    setSubmenu={setSubmenu}
                                />
                            ) : pages ? (
                                <SidebarDrawer
                                    key={label}
                                    icon={icon}
                                    label={label}
                                    pages={pages}
                                    isSidebarOpen={isOpen}
                                    submenu={submenu}
                                    setSubmenu={setSubmenu}
                                />
                            ) : (
                                <div key={label} />
                            ),
                    )}
                </>
            )} */}
            {isAdmin && (
                <>
                    <Box mt={4} mb={2}>
                        <Text
                            fontSize="12px"
                            color="gray.500"
                            fontWeight="bold"
                            pl={isOpen ? 5 : '10px'}
                            transition="0.4s"
                        >
                            ADMIN
                        </Text>
                    </Box>
                    {adminItems.map(({ icon, label, to, isNew }) => (
                        <SidebarTab
                            key={label}
                            icon={icon}
                            to={to}
                            label={label}
                            isNew={isNew}
                            isOpen={isOpen}
                            setSubmenu={setSubmenu}
                        />
                    ))}
                </>
            )}
        </>
    )
}
