import React from 'react'
import { Text, Center, CenterProps } from '@chakra-ui/layout'

interface Props extends CenterProps {
    title: string
    subtitle?: string
}

export default function EmptyState({ title, subtitle, ...props }: Props) {
    return (
        <Center flexDir="column" w="full" h="full" gridGap={1} {...props}>
            <Text fontSize="lg" fontWeight="bold">
                {title}
            </Text>
            <Text fontSize="sm" color="gray.500">
                {subtitle ||
                    `Something went wrong. Please try again or contact support@chckvet.com
                        for more help.`}
            </Text>
        </Center>
    )
}
