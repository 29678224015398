import React from 'react'
import {
    Avatar,
    Box,
    Button,
    HStack,
    Link,
    Menu,
    MenuButton,
    MenuGroup,
    MenuList,
    Text,
} from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { AdminRoutes, UserRoutes } from '~config/routes'
import { useUserContext } from '~config/user-context'
import MenuItem from '~components/shared/menu/menu-item'
import { ClinicFieldsFragment } from '~graphql/generated/graphql'
import { getSupabaseLink, SupabaseEntities } from '~config/constants'
import {
    BiCopy,
    BiRefresh,
    BiInfoCircle,
    BiLinkExternal,
    BiCog,
} from 'react-icons/bi'
import { formatClinicName } from '~utils/helpers'

interface Props {
    clinic: ClinicFieldsFragment
    setClinic: (e: ClinicFieldsFragment | undefined) => void
    isClinicAdmin: boolean
    canSwitch?: boolean
}

const ClinicHeader: React.FC<Props> = ({
    clinic,
    setClinic,
    isClinicAdmin,
    canSwitch = true,
}) => {
    const { isAdmin } = useUserContext()

    if (!isClinicAdmin && !canSwitch && !isAdmin)
        return (
            <Button
                variant="subtle"
                px={3}
                size="xs"
                textTransform="uppercase"
                colorScheme="blue"
                userSelect={'none'}
                cursor="default"
            >
                {formatClinicName(clinic.name)}
            </Button>
        )

    return (
        <HStack>
            <Menu placement="bottom-end">
                <MenuButton
                    as={Button}
                    variant="subtle"
                    px={3}
                    size="xs"
                    textTransform="uppercase"
                    colorScheme="blue"
                >
                    {formatClinicName(clinic.name)}
                </MenuButton>
                <MenuList pt={0}>
                    <Box px={4} py={2}>
                        <HStack spacing={4}>
                            <Avatar
                                size="sm"
                                src={clinic.avatar_url || ''}
                                name={clinic.name || ''}
                            />
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold">
                                    {formatClinicName(clinic.name)}
                                </Text>
                                {isAdmin && (
                                    <Text fontSize="xs" color="gray.500">
                                        {clinic.id}
                                    </Text>
                                )}
                            </Box>
                        </HStack>
                    </Box>
                    <MenuGroup title="Options" fontSize="xs">
                        {(isClinicAdmin || isAdmin) && (
                            <MenuItem
                                icon={BiInfoCircle}
                                onClick={() => navigate(UserRoutes.ClinicInfo)}
                            >
                                View Info
                            </MenuItem>
                        )}
                        {(canSwitch || isAdmin) && (
                            <MenuItem
                                icon={BiRefresh}
                                onClick={() => {
                                    setClinic(undefined)
                                    navigate(UserRoutes.SelectClinic)
                                }}
                            >
                                Switch Clinic
                            </MenuItem>
                        )}
                    </MenuGroup>
                    {isAdmin && (
                        <MenuGroup title="Admin" fontSize="xs">
                            <MenuItem
                                icon={BiCopy}
                                onClick={() =>
                                    navigator.clipboard.writeText(clinic.id)
                                }
                            >
                                Copy Clinic ID
                            </MenuItem>
                            <Link
                                variant="unstyled"
                                href={getSupabaseLink(
                                    SupabaseEntities.Clinic,
                                    clinic.id,
                                )}
                                isExternal
                            >
                                <MenuItem icon={BiLinkExternal}>
                                    View in Supabase
                                </MenuItem>
                            </Link>
                            <Link
                                variant="unstyled"
                                href={AdminRoutes.EditClinic(clinic.id)}
                            >
                                <MenuItem icon={BiCog}>Admin Settings</MenuItem>
                            </Link>
                        </MenuGroup>
                    )}
                </MenuList>
            </Menu>
        </HStack>
    )
}

export default ClinicHeader
