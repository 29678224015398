import { useEffect, useState } from 'react'
import {
    getIsDemoMode,
    toggleDemoMode as toggleDemoModeHelper,
} from '~utils/helpers'

export function useDemoMode() {
    const [isDemoMode, setIsDemoMode] = useState(getIsDemoMode())

    useEffect(() => {
        setIsDemoMode(getIsDemoMode())
    }, [])

    const toggleDemoMode = () => {
        const { isDemoMode: newIsDemoMode } = toggleDemoModeHelper()
        setIsDemoMode(newIsDemoMode)
        // refresh the page
        window.location.reload()
    }

    return { isDemoMode, toggleDemoMode }
}
