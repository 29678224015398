import React from 'react'
import { Avatar } from '@chakra-ui/avatar'
import { Text, Flex, HStack, SimpleGrid } from '@chakra-ui/layout'

import InfoItem, { InfoText } from '~components/shared/info-item'
import { ClientFieldsFragment } from '~graphql/generated/graphql'
import AdminIdButton from '~components/shared/buttons/admin-id-button'
import { SupabaseEntities } from '~config/constants'
import AddressDisplay from '~components/shared/address-display'
import ClientPhonesInfo from './phone-settings'
import { formatEmail, getClientFullName } from '~utils/helpers'

interface Props {
    client: ClientFieldsFragment
}

export default function DetailedClientHeader({ client }: Props) {
    const clientPhones = client.client_phones ?? []
    return (
        <>
            <Flex mb={4} justifyContent="space-between" alignItems="center">
                <HStack spacing={4}>
                    <Avatar
                        src={client.user?.avatar_url || ''}
                        name={getClientFullName(client, true) || ''}
                    />
                    <Text fontWeight="bold" fontSize="xl">
                        {getClientFullName(client)}
                    </Text>
                </HStack>
                <AdminIdButton
                    entity={SupabaseEntities.Client}
                    id={client.id}
                />
            </Flex>
            <SimpleGrid columns={2} spacing={4} mb={4}>
                <InfoItem
                    label="Email"
                    info={<InfoText>{formatEmail(client?.email)}</InfoText>}
                />
                <InfoItem
                    label="Address"
                    info={
                        <InfoText>
                            {client.address ? (
                                <AddressDisplay address={client.address} />
                            ) : null}
                        </InfoText>
                    }
                />
            </SimpleGrid>
            <ClientPhonesInfo client={client} />
        </>
    )
}
